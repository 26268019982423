import icon from './assets/icon.png';
import './App.css';

function App() {

  if (window.location.href.endsWith('/baixar')) {
    const userAgent = navigator.userAgent;
    const isiOS = !!userAgent.match(/iPhone|iPad|iPod/);
    const isAndroid = /android/i.test(userAgent);

    if (isiOS) {
      window.location.href = 'https://apps.apple.com/sa/app/gest%C3%A3o-safra/id6480516817';
    } else if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=br.com.gestaosafra';
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={icon} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
